<template>
  <div>
    <div class="modal_header" style="margin: -1rem -1.2rem 0">
      <el-row :gutter="10" class="pt-2 pl-2">
        <el-col :xs="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple">
            <el-button-group>
              <el-button
                class="back_button"
                type="primary"
                icon="el-icon-arrow-left"
                @click="resetForm()"
                >{{ $t("message.go_back") }}
              </el-button>
            </el-button-group>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :lg="8" :xl="8" class="text-center">
          {{ $t("message.patient_0") }}:
          <b style="text-transform: uppercase">{{
            model.patient
              ? model.patient.first_name + " " + model.patient.surname
              : " - "
          }}</b>
        </el-col>
      </el-row>
    </div>
    <div class="grid-content p-1">
      <div>
        <div v-loading="loadingData">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span> {{ $t("message.order") }}</span>
            </div>
            <div>
              <el-table :data="tableOrderData" style="width: 100%">
                <el-table-column label="№" width="110">
                  <template slot-scope="item"> # {{ item.row.id }} </template>
                </el-table-column>
                <el-table-column label="Партнер клиника">
                  <template slot-scope="item">
                    {{
                      item.row.partner_clinic
                        ? item.row.partner_clinic.name
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Партнер доктор">
                  <template slot-scope="item">
                    {{
                      item.row.partner_doctor
                        ? item.row.partner_doctor.name
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="Материал">
                  <template slot-scope="scope">
                    {{ scope.row.material ? scope.row.material.name : "" }}
                  </template>
                </el-table-column>
                <el-table-column label="Дата приема заказа">
                  <template slot-scope="item">
                    {{ item.row.created_at }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
          <!-- Patinet block -->
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span> {{ $t("message.patient") }}</span>
            </div>
            <div>
              <el-table :data="tablePatientData" style="width: 100%">
                <el-table-column label="№" width="110">
                  <template slot-scope="scope"> # {{ scope.row.id }} </template>
                </el-table-column>
                <el-table-column
                  prop="first_name"
                  :label="$t('message.first_name')"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="surname" :label="$t('message.surname')">
                </el-table-column>
                <el-table-column
                  prop="patronymic"
                  :label="$t('message.patronymic')"
                >
                </el-table-column>
                <el-table-column prop="born_date" :label="$t('message.dob')">
                </el-table-column>
                <el-table-column prop="gender" :label="$t('message.gender')">
                </el-table-column>
                <el-table-column prop="code" :label="$t('message.code')">
                </el-table-column>
                <el-table-column prop="phone" :label="$t('message.phone')">
                </el-table-column>
                <el-table-column :label="$t('message.social')">
                  <template slot-scope="scope">
                    {{ scope.row.social ? scope.row.social.name : "" }}
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" :label="$t('message.date')">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
          <!-- <el-card class="box-card mt-2">
                        <el-collapse
                            class="open_cliet_hstori mb-4"
                            v-model="activeCollapse"
                        >
                            <el-collapse-item
                                :title="$t('message.patient_info')"
                                name="1"
                            >
                                <el-row>
                                    <el-col :span="6">
                                        {{ $t("message.fio") }}:
                                        <b>{{
                                            patient
                                                ? patient.first_name +
                                                  " " +
                                                  patient.surname +
                                                  " " +
                                                  (patient.patronymic
                                                      ? patient.patronymic
                                                      : "")
                                                : ""
                                        }}</b>
                                    </el-col>
                                    <el-col :span="6">
                                        {{ $t("message.phone") }}:
                                        <b>{{
                                            patient ? patient.phone : "-"
                                        }}</b>
                                    </el-col>
                                    <el-col :span="6">
                                        {{ $t("message.dob") }}:
                                        <b>{{
                                            patient ? patient.born_date : "-"
                                        }}</b>
                                    </el-col>
                                    <el-col :span="6">
                                        {{ $t("message.gender") }}:
                                        <b>{{
                                            patient ? patient.gender : "-"
                                        }}</b>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                        </el-collapse>
                    </el-card> -->
          <!-- Services Data -->
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span> {{ $t("message.order_service") }}</span>
            </div>
            <div>
              <el-table :data="tableServicesData" style="width: 100%">
                <el-table-column type="index" label="#" width="80">
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('message.service_name')"
                  width="280"
                >
                </el-table-column>
                <el-table-column prop="price" :label="$t('message.price2')">
                  <template slot-scope="scope">
                    {{ scope.row.price | formatMoney }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="discount_price"
                  :label="$t('message.discount')"
                >
                </el-table-column>
                <el-table-column prop="code" :label="$t('message.code')">
                </el-table-column>
                <el-table-column prop="lifetime" label=" Срок услуги ">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PatientAllConclusion from "./Parts/PatientAllConclusion";
import createConclusion from "./createConclusion";
import PatientDoctorConclusion from "./Parts/PatientDoctorConclusion";
import CrmCreate from "./createConclusion";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import _ from "lodash";

export default {
  mixins: [form, drawerChild],
  components: {
    createConclusion,
    PatientDoctorConclusion,
    CrmCreate,
    PatientAllConclusion,
  },
  props: {
    reloadModel: { type: Boolean, default: true },
    selected: { type: Object, default: true },
  },
  data() {
    return {
      drawerCreate: false,
      id: null,
      activeCollapse: "1",
      activeCollapse2: "1",
      activeName: "first",
      loadingButton: false,
      loadingData: false,
      form: {},
      tableOrderData: [],
      tablePatientData: [],
      tableServicesData: [],
      changeServiceId: null,
      patient: null,
    };
  },

  created() {
    if (this.condition_list.length == 0) {
      this.conditions();
    }
  },
  async mounted() {
    this.form = JSON.parse(JSON.stringify(this.model));
  },
  computed: {
    ...mapGetters({
      rules: "orders/rules",
      model: "orders/relation_model",
      columns: "orders/columns",
      condition_list: "conditions/list",
    }),
  },
  methods: {
    ...mapActions({
      conditions: "conditions/iventory",
      save: "orders/updateStatus",
      showModel: "orders/relationShow",
      conclusionList: "conclusions/index",
    }),
    submit() {
      if (!this.form.condition_id) {
        this.activeName = "first";
        // this.$refs.condition_id.focus()
        this.$nextTick(() => {
          this.$refs.condition_id.focus();
        });
        return;
      }
      this.loadingButton = true;
      const query = {
        id: this.model.id,
        condition_id: this.form.condition_id,
      };
      this.save(query)
        .then((res) => {
          this.loadingButton = false;
          this.$alert(res);
        })
        .catch((err) => {
          this.$alert(err);
          this.loadingButton = false;
        });
    },
    fetchData() {
      this.loadingData = true;
      const query = { id: this.selected.id };
      this.showModel(query)
        .then((res) => {
          this.setGroupedOrderData(res.data.order);
          this.tableServicesData = res.data.order.services;
          this.tablePatientData.push(res.data.order.patient);
          this.loadingData = false;
          // this.fetchDataConclusions()
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    setGroupedOrderData(item) {
      this.tableOrderData.push({
        id: item.id,
        partner_clinic: item.partner_clinic,
        partner_doctor: item.partner_doctor,
        material: item.material,
        created_at: item.created_at,
      });
    },
    afterClosed() {
      this.tableOrderData = [];
      this.tablePatientData = [];
      this.tableServicesData = [];
    },
    fetchDataConclusions() {
      const query = { patient_id: this.id };
      this.conclusionList(query)
        .then((res) => {})
        .catch((err) => {});
    },
    createPatientConclusion(service_id) {
      this.changeServiceId = service_id;
      this.drawerCreate = true;
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawerShowCoculation" });
    },
    closeDrawer() {
      this.drawerCreate = false;
    },
    afterOpened() {
      this.patient = this.selected.patient;
      this.fetchData();
    },
    afterClose() {
      this.$refs["createDrawer"].afterClosed();
    },
  },
};
</script>
<style>
.back_button a {
  color: #ffffff;
}
.back_button a:hover {
  text-decoration: none;
}
</style>